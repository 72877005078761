<template>
  <div id="page-not-found">
    <Headline :text="$t('page_not_found.heading')" />
    <v-row>
      <v-col class="col-12">
        <p>{{ $t('page_not_found.first_row') }}</p>
        <p>&nbsp;</p>
        <p>
          <PrimaryButton url="/dashboard">{{ $t('misc.dashboard') }}</PrimaryButton>
        </p>
      </v-col>
      <v-col class="col-12">
        <img alt="404" src="@/assets/images/dog.png" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Headline from '../../components/app/Headline'
import PrimaryButton from '@/components/app/PrimaryButton.vue'

export default {
  components: {
    PrimaryButton,
    Headline
  }
}
</script>

<style lang="sass">
#page-not-found
  text-align: center

  img
    max-width: 500px
    width: 70%
</style>
